angular.module('baustellen').controller('BaustellenEditController', BaustellenEditController);

function BaustellenEditController($scope, BaustellenFacade, editedBaustelle) {

  $scope.loading = false;

  $scope.baustelle = editedBaustelle || {};

  $scope.alerts = [];

  $scope.save = (form) => {
    if(form.$valid) {
      $scope.loading = true;
      BaustellenFacade.save($scope.baustelle)
        .then(() => {
          $scope.loading = false;
          $scope.$close();
        })
        .catch((res) => {
          $scope.loading = false;
          if(res.status === 409) {
            $scope.alerts.push({type: 'warning', msg: 'Fehler! Git es schon eine Baustelle die so heißt?'});
          } else {
            $scope.alerts.push({type: 'danger', msg: 'Fehler! Bitte versuchen Sie es später noch einmal.'});
          }
        });
    }
  };

}
