angular.module('fotos').service('FotosFacade', FotosFacade);

function FotosFacade(Fotos, FileExport, $http, $uibModal, Angularytics) {

  this.getData = (queryParams) => Fotos.getAll(queryParams).$promise;

  this.new = () => $uibModal.open({
    templateUrl: 'templates/fotos/edit.html',
    controller: 'FotosEditController',
    resolve: {
      'editedFoto': () => {
      }
    }
  });

  this.save = Fotos.save;

  this.delete = (foto) =>  $uibModal
    .open({templateUrl: 'templates/fotos/delete.html'}).result
    .then(() => Fotos.delete(foto));

  this.export = (images, exportName) => {
    if (images.length) {
      Angularytics.trackEvent('Export', 'Fotos');
      return Promise.all(images.map(downloadImage))
        .then(fotos => fotos.filter((foto) => foto.data))
        .then(generateZipFile)
        .then((content) => FileExport.exportFile(exportName, content));
    }
  };

  let downloadImage = (foto) => $http.get(foto.url, {responseType: 'blob'})
    .then((response) => {
      return {name: foto._id + '.png', data: response.data};
    });

  let generateZipFile = (images) => {
    let zip = new JSZip();
    images.forEach((image) => zip.file(image.name, image.data));
    return zip.generateAsync({type: 'blob'});
  };
}
