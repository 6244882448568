angular.module('common').service('Subunternehmer', Subunternehmer);

function Subunternehmer($resource, BACKEND_URL, AuditService, User) {

  let methods = {
    getAll: {
      method: 'GET',
      url: BACKEND_URL + '/_all_docs',
      params: {
        include_docs: 'true',
        startkey: '\"p-\"',
        endkey: '\"p-\uf0000\"'
      },
      isArray: true,
      transformResponse: (data) => data && angular.fromJson(data).rows ? angular.fromJson(data).rows.map((row) => row.doc).map(addFullName).map(addUsername) : data
    },
    update: {
      method: 'PUT'
    }
  };

  let addFullName = (person) => {
    person.name = person.nachname + ', ' + person.vorname;
    return person;
  };

  let addUsername = (person) => {
    person.username = person._id.replace('p-', '');
    return person;
  };

  let resource = $resource(BACKEND_URL + '/:id', {id: '@id'}, methods);

  this.getAll = resource.getAll;

  this.create = (entity) => {
    let subunternehmer = {
      _id: getSlug('p-' + entity.username),
      type: 'personal',
      vorname: entity.vorname,
      nachname: entity.nachname,
      mandant: entity.mandant,
      adresse: entity.adresse,
      telefon: entity.telefon,
      teamadmin: entity.teamadmin,
      deactivated: false
    };
    AuditService.addOrUpdateAuditInfo(subunternehmer);

    return User.create(entity)
      .then(() => resource.save(subunternehmer).$promise);
  };


  this.update = (entity) => {
    let subunternehmer = {
      _id: entity._id,
      _rev: entity._rev,
      type: entity.type,
      vorname: entity.vorname,
      nachname: entity.nachname,
      mandant: entity.mandant,
      adresse: entity.adresse,
      telefon: entity.telefon,
      teamadmin: entity.teamadmin,
      deactivated: entity.deactivated || false,
      created_at: entity.created_at,
      created_by: entity.created_by
    };
    AuditService.addOrUpdateAuditInfo(subunternehmer);
    return resource.update({id: subunternehmer._id}, subunternehmer).$promise
      .then(() => User.update(entity));
  };
}
