angular.module('common').service('Taetigkeiten', Taetigkeiten);

function Taetigkeiten($resource, BACKEND_URL, AuditService) {

  let methods = {
    getAll : {
      method: 'GET',
      url: BACKEND_URL + '/_all_docs',
      params: {
        include_docs: 'true',
        startkey : '\"t-\"',
        endkey: '\"t-\uf0000\"'
      },
      isArray: true,
      transformResponse: (data) => data && angular.fromJson(data).rows ? angular.fromJson(data).rows.map((row) => row.doc) : data
    },
    update: {
      method: 'PUT'
    }
  };

  let resource = $resource(BACKEND_URL + '/:id', {id: '@id'}, methods);

  this.getAll = resource.getAll;

  this.save = (doc) => {
    doc.type = 'taetigkeit';
    doc = AuditService.addOrUpdateAuditInfo(doc);

    if (doc._id) {
      return resource.update({id: doc._id}, doc).$promise;
    }
    doc._id = getSlug('t-' + doc.name);
    return resource.save(doc).$promise;
  };

  this.deactivate = (doc) => {
    doc.deactivated = true;
    return resource.update({id: doc._id}, doc).$promise;
  };
}
