angular.module('subunternehmer').controller('SubunternehmerNewController', SubunternehmerNewController);

function SubunternehmerNewController($scope, SubunternehmerFacade) {

  $scope.loading = false;

  $scope.subunternehmer = {};

  $scope.alerts = [];

  $scope.save = (form) => {
    if(form.$valid) {
      $scope.loading = true;
      SubunternehmerFacade.save($scope.subunternehmer)
        .then(() => {
          $scope.loading = false;
          $scope.$close();
        })
        .catch((res) => {
          if (res.status === 409) {
            $scope.alerts.push({
              type: 'warning',
              msg: 'Fehler! Einen Usernamen mit dem gewählten Benutzernamen gibt es schon.'
            });
          } else {
            $scope.alerts.push({type: 'danger', msg: 'Fehler! Bitte versuchen Sie es später noch einmal.'});
          }
          $scope.loading = false;
        });
    }
  };

}
