'use strict';

angular.module('berichtconfig')
  .controller('BerichtconfigController', BerichtconfigController);

function BerichtconfigController($scope, BACKEND_BASE_URL, BerichtconfigService, $window, Upload) {
  $scope.alerts = [];

  $scope.logoUrl = `${BACKEND_BASE_URL}/config/logo/logo`;

  $scope.newLogo = {};

  $scope.loading = false;


  $scope.saveNewLogo = (form) => {
    if (form.$valid && $scope.newLogo) {
      $scope.loading = true;
      BerichtconfigService.save($scope.newLogo)
        .then(() => {
          $scope.loading = false;
          $window.location.reload();
        })
        .catch(() => {
          $scope.loading = false;
          $scope.alerts.push({type: 'danger', msg: 'Fehler! Bitte versuchen Sie es später noch einmal.'});
        });
    }
  };
}
