'use strict';
angular.module('mcZeiterfassungApp')
  .config(function ($stateProvider, $urlRouterProvider) {

    $stateProvider
      .state('login', {
        url: '/login',
        controller: 'LoginController',
        templateUrl: 'templates/login/login.html'
      })
      .state('main', {
        templateUrl: 'templates/main.html',
        controller: function($scope, AuthService){
          $scope.isTeamAdmin = AuthService.isTeamAdmin();
        },
        resolve: {
          authorize: ['AuthService', ((AuthService) => AuthService.isAuthenticated())]
        }
      })
      .state('main.baustellen', {
        url: '/baustellen',
        controller: 'BaustellenIndexController',
        templateUrl: 'templates/baustellen/index.html'
      })
      .state('main.taetigkeiten', {
        url: '/taetigkeiten',
        controller: 'TaetigkeitenIndexController',
        templateUrl: 'templates/taetigkeiten/index.html'
      })
      .state('main.subunternehmer', {
        url: '/subunternehmer',
        controller: 'SubunternehmerIndexController',
        templateUrl: 'templates/subunternehmer/index.html'
      })
      .state('main.arbeitszeiten', {
        url: '/arbeitszeiten',
        controller: 'ArbeitszeitenIndexController',
        templateUrl: 'templates/arbeitszeiten/index.html'
      })
      .state('main.fotos', {
        url: '/fotos',
        controller: 'FotosIndexController',
        templateUrl: 'templates/fotos/index.html'
      })
      .state('main.stundenauswertung', {
        url: '/stundenauswertung',
        controller: 'StundenauswertungController',
        templateUrl: 'templates/reports/stundenauswertung/index.html'
      })
      .state('main.bautagesbericht', {
        url: '/bautagesbericht',
        controller: 'BautagesberichtController',
        templateUrl: 'templates/reports/bautagesbericht/index.html'
      })
      .state('main.reportconfig', {
        url: '/berichtconfig',
        controller: 'BerichtconfigController',
        templateUrl: 'templates/reports/config/index.html'
      })

    .state('main.admins', {
      url: '/admins',
      controller: 'AdminIndexController',
      templateUrl: 'templates/admin/index.html'
    });

    $urlRouterProvider.otherwise(function ($injector) {
      var $state = $injector.get('$state');
      $state.go('main.arbeitszeiten');
    });
  });
