angular.module('login').controller('LoginController', LoginController);

function LoginController($scope, LoginService) {
  $scope.credentials = {};

  $scope.authenticationError = false;

  $scope.notAdmin = false;

  $scope.loading = false;

  $scope.logout = LoginService.logout;

  $scope.login = function () {
    if ($scope.credentials.username && $scope.credentials.password) {
      $scope.loading = true;
      LoginService
        .login($scope.credentials.username.toLowerCase(), $scope.credentials.password)
        .then(LoginService.goToNextView)
        .catch((res) => {
          if(res.notAdmin) {
            $scope.notAdmin = true;
            $scope.authenticationError = false;
          } else {
            $scope.notAdmin = false;
            $scope.authenticationError = true;
          }
          $scope.loading = false;
        });
    }
  };
}
