'use strict';

angular.module('bautagesbericht')
  .factory('BautagesberichtService', function ($window, REPORTS_URL) {
    return {
      get: function (fromDate, toDate, baustelle, mitFotos) {
        fromDate = moment(fromDate).toISOString().slice(0, 10);
        toDate = moment(toDate).toISOString().slice(0, 10);
        $window.location = REPORTS_URL + '/reports/baustellenbericht?von='+fromDate+'&bis='+toDate+'&baustelleId='+baustelle+'&mitFotos='+mitFotos;
      }
    };
  });
