angular.module('baustellen').controller('BaustellenIndexController', BaustellenIndexController);

function BaustellenIndexController($scope, $filter, TableConfiguration, BaustellenFacade) {
  $scope.alerts = [];

  $scope.data = [];

  $scope.loading = true;

  $scope.tableParams = TableConfiguration.createTableConfiguration($scope);

  let loadData = () => {
    $scope.loading = true;
    BaustellenFacade.getData().then((data) => {
      $scope.data = $filter('orderBy')(data, ['-deactivated'], false);
      $scope.tableParams.page(1);
      $scope.tableParams.reload();
      $scope.loading = false;
    }).catch(error => {
      $scope.alerts.push({type: 'danger', msg: 'Fehler! Bitte versuchen Sie es später noch einmal.'});
      ga('send', 'event', 'Network error', error, error, 0, true);
    });
  };

  loadData();

  $scope.new = () => BaustellenFacade.new().result.then(loadData);

  $scope.delete = (baustelle) => BaustellenFacade.delete(baustelle).then(loadData);

  $scope.edit = (baustelle) => BaustellenFacade.edit(baustelle).result.then(loadData);
}
