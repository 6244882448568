angular.module('common').service('Baustellen', Baustellen);

function Baustellen($resource, BACKEND_URL, AuditService) {

  let methods = {
    getAll: {
      method: 'GET',
      url: BACKEND_URL + '/_all_docs',
      params: {
        include_docs: 'true',
        startkey: '\"b-\"',
        endkey: '\"b-\uf0000\"'
      },
      isArray: true,
      transformResponse: (data) => data && angular.fromJson(data).rows ? angular.fromJson(data).rows.map((row) => row.doc) : data
    },
    update: {
      method: 'PUT'
    }
  };

  let resource = $resource(BACKEND_URL + '/:id', {id: '@id'}, methods);

  this.getAll = resource.getAll;

  this.save = (baustelle) => {
    baustelle.type = 'baustelle';
    baustelle = AuditService.addOrUpdateAuditInfo(baustelle);

    if (baustelle._id) {
      return resource.update({id: baustelle._id}, baustelle).$promise;
    }
    baustelle._id = getSlug('b-' + baustelle.name);
    return resource.save(baustelle).$promise;
  };


  this.delete = (baustelle) => {
    baustelle._deleted = true;
    return resource.update({id: baustelle._id}, baustelle).$promise;
  };

}
