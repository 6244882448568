angular.module('admins').controller('AdminIndexController', AdminIndexController);

function AdminIndexController($scope, TableConfiguration, AdminsFacade) {
  $scope.data = [];

  $scope.loading = true;

  $scope.tableParams = TableConfiguration.createTableConfiguration($scope);

  let loadData = () => {
    $scope.loading = true;
    AdminsFacade.getData().then((data) => {
      $scope.data = data;
      $scope.tableParams.reload();
      $scope.loading = false;
    });
  };

  loadData();

  $scope.new = () => AdminsFacade.new().result.then(loadData);

  $scope.delete = (baustelle) => AdminsFacade.delete(baustelle).then(loadData);
}
