angular.module('common')
  .service('TableConfiguration', function (ngTableParams, $filter) {
    this.filterDataForExport = (data, params) => filterAndSort(data, params);

    this.createTableConfiguration = (scope) => {
      return new ngTableParams({page: 1, count: 10}, {
        total: scope.data.length,
        getData: function ($defer, params) {
          let from = (params.page() - 1) * params.count();
          let to = params.page() * params.count();

          let orderedData = filterAndSort(scope.data, params);
          params.total(orderedData.length);
          $defer.resolve(orderedData.slice(from, to));
        }
      });
    };

    function filterAndSort(data, params) {
      var filteredData = params.filter() ? applyFilter(data, params.filter(true)) : data;
      return params.sorting() ? $filter('orderBy')(filteredData, params.orderBy()) : filteredData;
    }

    function applyFilter(data, filter) {
      var filterKeys = Object.keys(filter);
      var parsedFilter = filterKeys.reduce(function (result, key) {
        result = setPath(result, filter[key], key);
        return result;
      }, {});
      return $filter('filter')(data, parsedFilter);
    }

    function setPath(obj, value, path) {
      var keys = path.split('.');
      var ret = obj;
      var lastKey = keys[keys.length - 1];
      var target = ret;

      var parentPathKeys = keys.slice(0, keys.length - 1);
      parentPathKeys.forEach(function (key) {
        if (!target.hasOwnProperty(key)) {
          target[key] = {};
        }
        target = target[key];
      });

      target[lastKey] = value;
      return ret;
    }
  });
