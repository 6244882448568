angular.module('taetigkeiten').controller('TaetigkeitenIndexController', TaetigkeitenIndexController);

function TaetigkeitenIndexController($scope, TableConfiguration, TaetigkeitenFacade) {
  $scope.alerts = [];

  $scope.data = [];

  $scope.loading = true;

  $scope.tableParams = TableConfiguration.createTableConfiguration($scope);

  let loadData = () => {
    $scope.loading = true;
    TaetigkeitenFacade.getData().then((data) => {
      $scope.data = data.filter(t => !t.deactivated);
      $scope.tableParams.reload();
      $scope.loading = false;
    }).catch(error => {
      $scope.alerts.push({type: 'danger', msg: 'Fehler! Bitte versuchen Sie es später noch einmal.'});
      ga('send', 'event', 'Network error', error, error, 0, true);
    });
  };

  loadData();

  $scope.new = () => TaetigkeitenFacade.new().result.then(loadData);

  $scope.delete = (doc) => TaetigkeitenFacade.delete(doc).then(loadData);

  $scope.edit = (doc) => TaetigkeitenFacade.edit(doc).result.then(loadData);

  $scope.export = () => TaetigkeitenFacade.exportCsv(TableConfiguration.filterDataForExport($scope.data, $scope.tableParams));

}
