angular.module('subunternehmer').controller('SubunternehmerIndexController', SubunternehmerIndexController);

function SubunternehmerIndexController($scope, $filter, TableConfiguration, SubunternehmerFacade){
  $scope.alerts = [];

  $scope.data = [];

  $scope.loading = true;

  $scope.tableParams = TableConfiguration.createTableConfiguration($scope);

  let loadData = () => {
    $scope.loading = true;
    SubunternehmerFacade.getData().then((data) => {
      $scope.data = $filter('orderBy')(data, ['-deactivated'], false);
      $scope.tableParams.reload();
      $scope.loading = false;
    }).catch(error => {
      $scope.alerts.push({type: 'danger', msg: 'Fehler! Bitte versuchen Sie es später noch einmal.'});
      ga('send', 'event', 'Network error', error, error, 0, true);
    });
  };

  loadData();

  $scope.new = () => SubunternehmerFacade.new().result.then(loadData);

  $scope.delete = (subunternehmer) => SubunternehmerFacade.delete(subunternehmer).then(loadData);

  $scope.edit = (subunternehmer) => SubunternehmerFacade.edit(subunternehmer).result.then(loadData);

}
