angular.module('admins').service('AdminsFacade', AdminsFacade);

function AdminsFacade(Admin, $uibModal) {

  this.getData = () => Admin.getAll();

  this.new = () => $uibModal.open({
    templateUrl: 'templates/admin/new.html',
    controller: 'AdminNewController',
    resolve: {
      'editedBaustelle': () => {}
    }
  });

  this.save = Admin.save;

  this.delete = (admin) =>  $uibModal
    .open({templateUrl: 'templates/admin/delete.html'}).result
    .then(() => Admin.delete(admin));

}
