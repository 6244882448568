angular.module('subunternehmer').controller('SubunternehmerPasswordController', SubunternehmerPasswordController);

function SubunternehmerPasswordController($scope, SubunternehmerFacade, editedSubunternehmer) {

  $scope.loading = false;

  $scope.subunternehmer = editedSubunternehmer;

  $scope.alerts = [];

  $scope.save = (form) => {
    if(form.$valid) {
      $scope.loading = true;
      let username = $scope.subunternehmer._id.replace('p-', '');
      SubunternehmerFacade.changePassword(username, $scope.subunternehmer.password)
        .then(() => {
          $scope.loading = false;
          $scope.$close();
        })
        .catch(() => {
          $scope.loading = false;
          $scope.alerts.push({type: 'danger', msg: 'Fehler! Bitte versuchen Sie es später noch einmal.'});
        });
    }
  };

}
