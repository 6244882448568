angular.module('arbeitszeiten').service('ArbeitszeitenFacade', ArbeitszeitenFacade);

function ArbeitszeitenFacade(Arbeitszeiten, FileExport, $uibModal, Angularytics) {

  const CSV_EXPORT_HEADER = 'Datum;Subunternehmer;Mandant;Baustelle;Kostenstelle1;Kostenstelle2;Tätigkeit;Artikelnummer;Artikelname;Von;Bis;Stunden;Etage;Wohnung;Raum;Bauteil;Kommentar';
  const CSV_EXPORT_HEADER_SUMMARY = ';Subunternehmer;Kostenstelle1;Kostenstelle2;Artikelnummer;Artikelname;Stunden';

  this.getData = (queryParams) => Arbeitszeiten.getAll(queryParams).$promise;

  this.new = () => $uibModal.open({
    templateUrl: 'templates/arbeitszeiten/edit.html',
    controller: 'ArbeitszeitenEditController',
    resolve: {
      'editedDoc': () => {
      }
    }
  });

  this.save = Arbeitszeiten.save;

  this.delete = (doc) =>  $uibModal
    .open({templateUrl: 'templates/arbeitszeiten/delete.html'}).result
    .then(() => Arbeitszeiten.delete(doc));

  this.edit = (doc) => $uibModal.open({
    templateUrl: 'templates/arbeitszeiten/edit.html',
    controller: 'ArbeitszeitenEditController',
    resolve: {
      'editedDoc': () => angular.copy(doc)
    }
  });

  this.exportCsv = (arbeitszeiten) => {
    Angularytics.trackEvent('Export', 'CSV');
    var mainData = arbeitszeiten.reduce((acc, current) => acc + '\n' + convertToMainDataCsvRow(current), '');
    var summary = calculateSummary(arbeitszeiten).map(convertToSummaryCsvRow).join('\n');

    const data = [];
    data.push(CSV_EXPORT_HEADER + '\n');
    data.push(mainData);
    data.push('\n\n\n\n\n');
    data.push(CSV_EXPORT_HEADER_SUMMARY + '\n');
    data.push(summary);

    return FileExport.exportCsv(data.join(''));
  };

  let convertToMainDataCsvRow = (az) => [
    moment(az.datum).format('DD.MM.YYYY'),
    az.personal.name,
    az.mandant || az.personal.mandant|| '-',
    az.baustelle.name,
    az.baustelle.projektnummer || '-',
    az.baustelle.projektnummer2|| '-',
    az.taetigkeit.name,
    az.taetigkeit.artikelnummer|| '-',
    az.taetigkeit.artikelname|| '-',
    az.von,
    az.bis,
    az.dauer,
    az.etage|| '-',
    az.wohnung|| '-',
    az.raum|| '-',
    az.bauteil|| '-',
    az.kommentar || '-'
  ].map(FileExport.escapeForCsv).join(';');


let convertToSummaryCsvRow = line => [
  '',
  line.name,
  line.kostenstelle1,
  line.kostenstelle2,
  line.artikelnummer,
  line.artikelname,
  (line.dauer + '').replace('.', ',')
].map(FileExport.escapeForCsv).join(';');

let calculateSummary = (zeiten) =>{
  const summary = zeiten.reduce((result, item) => {
    const groupId = [item.personal._id, item.baustelle.projektnummer, item.baustelle.projektnummer2, item.taetigkeit.artikelnummer, item.taetigkeit.artikelname];
    result[groupId] = result[groupId] || {
         name: item.personal.name,
         kostenstelle1: item.baustelle.projektnummer || '-',
         kostenstelle2: item.baustelle.projektnummer2 || '-',
         artikelnummer: item.taetigkeit.artikelnummer || '-',
         artikelname: item.taetigkeit.artikelname || '-',
         dauer: 0
    };
    result[groupId].dauer = result[groupId].dauer + (parseFloat((item.dauer || 0).replace(/,/, '.')));
    return result;
  }, {});

  return Object.values(summary);
};
}
