angular.module('taetigkeiten').service('TaetigkeitenFacade', TaetigkeitenFacade);

function TaetigkeitenFacade(Taetigkeiten, $uibModal, FileExport, Angularytics) {

  const CSV_EXPORT_HEADER = 'Name;Slowenisch-Übersetzung';

  this.getData = () => Taetigkeiten.getAll().$promise;

  this.new = () => $uibModal.open({
    templateUrl: 'templates/taetigkeiten/edit.html',
    controller: 'TaetigkeitenEditController',
    resolve: {
      'editedDoc': () => {
      }
    }
  });

  this.save = Taetigkeiten.save;

  this.delete = (doc) =>  $uibModal
    .open({templateUrl: 'templates/taetigkeiten/delete.html'}).result
    .then(() => Taetigkeiten.deactivate(doc));

  this.edit = (doc) => $uibModal.open({
    templateUrl: 'templates/taetigkeiten/edit.html',
    controller: 'TaetigkeitenEditController',
    resolve: {
      'editedDoc': () => angular.copy(doc)
    }
  });

  this.exportCsv = (taetigkeiten) => {
    Angularytics.trackEvent('Export', 'CSV-Taetigkeiten');
    return FileExport.exportCsv(taetigkeiten.reduce((acc, current) => acc + '\n' + convertToCsvRow(current), CSV_EXPORT_HEADER));
  };

  let convertToCsvRow = (t) => [
    t.name,
    t.name_si || '-'
  ].map(FileExport.escapeForCsv).join(';');
}
