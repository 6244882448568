angular.module('common').service('User', User);

function User($resource, BACKEND_BASE_URL, AuditService) {

  let resource = $resource(BACKEND_BASE_URL + '/_users/org.couchdb.user::id',
    {id: '@id'},
    {update: {method: 'PUT'}}
  );

  this.create = (entity) => {
    let user = {
      _id: 'org.couchdb.user:' + entity.username,
      type: 'user',
      name: entity.username,
      password: entity.password,
      roles: this.getRoles(entity),
      vorname: entity.vorname,
      nachname: entity.nachname
    };


    AuditService.addOrUpdateAuditInfo(entity);
    return resource.update({id: user.name}, user).$promise;
  };

  this.update = (entity) => {
    return resource.get({id: entity.username}).$promise
      .then((user) => {
        user.vorname = entity.vorname;
        user.nachname = entity.nachname;
        user.deactivated = entity.deactivated || false;
        user.roles = this.getRoles(entity);
        return resource.update({id: user.name}, user).$promise;
      });
  };

  this.getRoles = (entity) => {
    let roles = ['erfasser'];
    if (entity.teamadmin) {
      roles.push('teamadmin');
    }
    if (entity.deactivated) {
      roles.push('deactivated');
    }
    return roles;
  };

  this.changePassword = (username, newPassword) => {
    return resource.get({id: username}).$promise
      .then((user) => {
        user.password = newPassword;
        return resource.update({id: user.name}, user).$promise;
      });
  };
}
