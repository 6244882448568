angular.module('taetigkeiten').controller('TaetigkeitenEditController', TaetigkeitenEditController);

function TaetigkeitenEditController($scope, TaetigkeitenFacade, editedDoc) {

  $scope.loading = false;

  $scope.doc = editedDoc || {};

  $scope.alerts = [];

  $scope.save = (form) => {
    if(form.$valid) {
      $scope.loading = true;
      TaetigkeitenFacade.save($scope.doc)
        .then(() => {
          $scope.loading = false;
          $scope.$close();
        })
        .catch((res) => {
          $scope.loading = false;
          if(res.status === 409) {
            $scope.alerts.push({type: 'warning', msg: 'Fehler! Git es schon einen Eintrag der so heißt?'});
          } else {
            $scope.alerts.push({type: 'danger', msg: 'Fehler! Bitte versuchen Sie es später noch einmal.'});
          }
        });
    }
  };

}
