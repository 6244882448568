angular.module('baustellen').service('BaustellenFacade', BaustellenFacade);

function BaustellenFacade(Baustellen, $uibModal) {

  this.getData = () => Baustellen.getAll().$promise;

  this.new = () => $uibModal.open({
    templateUrl: 'templates/baustellen/edit.html',
    controller: 'BaustellenEditController',
    resolve: {
      'editedBaustelle': () => {}
    }
  });

  this.save = Baustellen.save;

  this.delete = (baustelle) =>  $uibModal
    .open({templateUrl: 'templates/baustellen/delete.html'}).result
    .then(() => Baustellen.delete(baustelle));


  this.edit = (baustelle) => $uibModal.open({
    templateUrl: 'templates/baustellen/edit.html',
    controller: 'BaustellenEditController',
    resolve: {
      'editedBaustelle': () => angular.copy(baustelle)
    }
  });

}
