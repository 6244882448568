angular.module('arbeitszeiten').controller('ArbeitszeitenEditController', ArbeitszeitenEditController);

function ArbeitszeitenEditController($scope, ArbeitszeitenFacade, Baustellen, Subunternehmer, Taetigkeiten, editedDoc) {
  $scope.baustellen = [];
  $scope.subunternehmer = [];
  $scope.taetigkeiten = [];
  var times = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map((i) => [i + ':00', i + ':15', i + ':30', i + ':45']);
  $scope.times = [].concat.apply([], times);

  if(editedDoc) {
   editedDoc.datum = moment(editedDoc.datum, 'YYYY-MM-DD').toDate();
  }

  $scope.arbeitszeit = editedDoc || {
    datum: new Date(),
    von: '09:00',
    bis: '17:00'
  };


  Baustellen.getAll((data) => {
    $scope.baustellen = data;
  });

  Subunternehmer.getAll((data) => {
    $scope.subunternehmer = data;
  });

  Taetigkeiten.getAll((data) => {
    $scope.taetigkeiten = data.filter(t => !t.deactivated);
  });

  $scope.loading = false;

  $scope.doc = editedDoc || {};

  $scope.alerts = [];

  $scope.save = (form) => {
    if(form.$valid) {
      $scope.loading = true;
      ArbeitszeitenFacade.save($scope.arbeitszeit)
        .then(() => {
          $scope.loading = false;
          $scope.$close();
        })
        .catch((res) => {
          $scope.loading = false;
          if(res.status === 409) {
            $scope.alerts.push({type: 'warning', msg: 'Fehler! Git es schon einen Eintrag der so heißt?'});
          } else {
            $scope.alerts.push({type: 'danger', msg: 'Fehler! Bitte versuchen Sie es später noch einmal.'});
          }
        });
    }
  };

}
