'use strict';

angular.module('stundenauswertung')
  .controller('StundenauswertungController', StundenauswertungController);

function StundenauswertungController($scope, $timeout, Subunternehmer, StundenauswertungBerichtService, Angularytics) {
  $scope.loading = false;

  $scope.toDate = new Date();
  $scope.fromDate = new Date();

  $scope.personen = [];
  $scope.person = '';

  Subunternehmer.getAll((data) => {
    $scope.personen = data.map(p => {
      p.status = p.deactivated ? 'Inaktiver Subunternehmer' : 'Aktiver Subunternehmer';
      return p;
    });
    $scope.person = data[0]._id;
  });

  $scope.downloadReport = () => {
    $scope.loading = true;
    $timeout(() => $scope.loading = false, 10000);
    StundenauswertungBerichtService.get($scope.fromDate, $scope.toDate, $scope.person);
    Angularytics.trackEvent('Reports', 'Stundenauswertung', $scope.person);
  };

}
