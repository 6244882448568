angular.module('fotos').service('Fotos', Fotos);

function Fotos($resource, AuditService, BACKEND_URL) {

  let methods = {
    getAll: {
      method: 'GET',
      url: BACKEND_URL + '/_design/arbeitszeiten/_list/foto/foto-with-relations',
      params: {
        include_docs: 'true'
      },
      isArray: true,
      transformResponse: (data) => data ? angular.fromJson(data).map(personalAddFullName).map(addFotoUrl).map(addCaption) : data
    },
    update: {
      method: 'PUT'
    }
  };

  let personalAddFullName = (foto) => {
    foto.personal.name = foto.personal.nachname + ', ' + foto.personal.vorname;
    return foto;
  };

  let addCaption = (foto) => {
    foto.caption = [
      moment(foto.datum).format('DD.MM.YYYY'),
      foto.baustelle.name,
      foto.personal.nachname + ' ' + foto.personal.vorname,
      foto.kommentar]
      .filter((e) => e).join(', ');
    return foto;
  };

  let addFotoUrl = (foto) => {
    foto.url = `${BACKEND_URL}/${foto._id}/foto`;
    return foto;
  };

  let fotosResource = $resource(`${BACKEND_URL}/:id`, {id: '@id'}, methods);

  this.getAll = fotosResource.getAll;

  this.delete = (doc) => {
      doc._deleted = true;
      return fotosResource.update({id: doc._id}, doc).$promise;
    };


  this.save = (fotoData, base64Image) => {
    let user = fotoData.personal_id.replace('p-','');
    let datum = moment(fotoData.datum).format('YYYY-MM-DD');
    let fotoDoc = {
      _id: getSlug('f-' + datum + '-' + fotoData.baustelle_id + '-' + user + '-' + uuid.v1()),
      type: 'foto',
      personal_id: fotoData.personal_id,
      baustelle_id: fotoData.baustelle_id,
      datum:  datum,
      kommentar: fotoData.kommentar || '',
      _attachments: {
        foto: {
          content_type: 'image/png',
          data: base64Image.substring(base64Image.indexOf(",") + 1)
        }
      }
    };
    AuditService.addOrUpdateAuditInfo(fotoDoc);
    fotoDoc.created_by = fotoData.personal_id;

    return fotosResource.save(fotoDoc).$promise;
  }
}
