angular.module('fotos').controller('FotosIndexController', FotosIndexController);

function FotosIndexController($scope, TableConfiguration, FotosFacade, Lightbox) {
  $scope.data = [];

  $scope.loading = true;

  $scope.tableParams = TableConfiguration.createTableConfiguration($scope);

  $scope.toDate = new Date();
  $scope.fromDate = new Date();

  let queryParams = () => {
    let von = moment($scope.fromDate);
    let bis = moment($scope.toDate);
    return {
      startkey: '["f-' + von.format('YYYY-MM-DD') + '"]',
      endkey: '["f-' + bis.format('YYYY-MM-DD') + '\uf0000"]'
    };
  };

  let loadData = () => {
    $scope.loading = true;
    FotosFacade.getData(queryParams()).then((data) => {
      $scope.data = data;
      $scope.tableParams.reload();
      $scope.loading = false;
    }).catch(error => {
      $scope.alerts.push({type: 'danger', msg: 'Fehler! Bitte versuchen Sie es später noch einmal.'});
      ga('send', 'event', 'Network error', error, error, 0, true);
    });
  };

  let onDateChange = () => {
    let von = moment($scope.fromDate);
    let bis = moment($scope.toDate);
    if (von.isSameOrBefore(bis)) {
      loadData();
      $scope.tableParams.page(1);
    }
  };
  $scope.$watchGroup(['fromDate', 'toDate'], onDateChange);

  $scope.zoomImage = (id) => Lightbox.openModal(TableConfiguration.filterDataForExport($scope.data, $scope.tableParams), getImageIndexById(id));

  let getImageIndexById = (id) => TableConfiguration.filterDataForExport($scope.data, $scope.tableParams).findIndex((img) => img._id === id);

  $scope.export = () => FotosFacade.export(TableConfiguration.filterDataForExport($scope.data, $scope.tableParams), `fotos-${moment($scope.fromDate).format('YYYY-MM-DD')}-${moment($scope.toDate).format('YYYY-MM-DD')}`);

  $scope.delete = (doc) => FotosFacade.delete(doc).then(loadData);

  $scope.new = () => FotosFacade.new().result.then(loadData);
}
