'use strict';
angular
  .module('mcZeiterfassungApp', [
    'ngResource',
    'ngSanitize',
    'ui.router',
    'baustellen',
    'taetigkeiten',
    'subunternehmer',
    'arbeitszeiten',
    'fotos',
    'stundenauswertung',
    'bautagesbericht',
    'berichtconfig',
    'login',
    'ngTable',
    'common',
    'ui.bootstrap',
    'admins',
    'angularytics'
  ])
  .run(function ($rootScope, VERSION) {
    $rootScope.VERSION = VERSION;
  }).run(function ($rootScope, $location, $state, AuthService) {
    $rootScope.$on('$stateChangeStart', function (e, toState) {
      var isLogin = toState.name === 'login';
      if (isLogin) {
        return; // no need to redirect
      }

      AuthService.isAuthenticated()
        .then((authenticated) => {
          if (!authenticated) {
            e.preventDefault();
            $state.go('login');
          }
        });
    });
  })
  .config(($httpProvider) => {
    $httpProvider.defaults.withCredentials = true;
  })
  .config((AngularyticsProvider) => AngularyticsProvider.setEventHandlers(['Console', 'GoogleUniversal']))
  .run((Angularytics) => Angularytics.init())
  .config(function ($provide) {
    $provide.decorator('$exceptionHandler', function ($delegate) {
      return function errorCatcherHandler(exception, cause) {
        $delegate(exception, cause);
        ga('send', 'event', 'AngularJS error', exception.message, exception.stack, 0, true);
      };
    });
  });
