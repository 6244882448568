angular.module('common').service('AuditService', AuditService);


function AuditService(AuthService) {

  this.addOrUpdateAuditInfo = (doc) => {
    let time = moment();
    let user = 'p-' + AuthService.getUsername();

    doc.created_at = doc.created_at || time.format();
    doc.created_by = doc.created_by || user;
    doc.updated_at = time.format();
    doc.updated_by = user;

    return doc;
  };

}
