angular.module('admins').controller('AdminNewController', AdminNewController);

function AdminNewController($scope, AdminsFacade) {

  $scope.loading = false;

  $scope.admin =  {};

  $scope.alerts = [];

  $scope.save = (form) => {
    if(form.$valid) {
      $scope.loading = true;
      AdminsFacade.save($scope.admin)
        .then(() => {
          $scope.loading = false;
          $scope.$close();
        })
        .catch((res) => {
          $scope.loading = false;
          if(res.status === 409) {
            $scope.alerts.push({type: 'warning', msg: 'Fehler! Git es schon einen Admin der so heißt?'});
          } else {
            $scope.alerts.push({type: 'danger', msg: 'Fehler! Bitte versuchen Sie es später noch einmal.'});
          }
        });
    }
  };

}
