angular.module('common').service('Admin', Admin);

function Admin($http, BACKEND_BASE_URL) {

  let url = BACKEND_BASE_URL + '/_node/nonode@nohost/_config/admins';

  this.getAll = () => $http.get(url)
    .then((res) => Object.keys(res.data).map((i) => { return {name: i}; }));

  this.save = (admin) => $http.put(url + '/' + admin.name, '"' + admin.password + '"');

  this.delete = (admin) => $http.delete(url + '/' + admin.name);

}
