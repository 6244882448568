angular.module('common').service('AuthService', AuthService);

function AuthService($http, LOGIN_URL) {
  let authenticated = $http.get(LOGIN_URL)
    .then((res) => {
      var userContext = res.data.userCtx;
      this.setLoggedIn(userContext.name, userContext.roles);
      ga('set', 'userId', userContext.name);
      return userContext.roles.indexOf('_admin') !== -1 || userContext.roles.indexOf('teamadmin') !== -1;
    })
    .catch(() => false);

  this.isAuthenticated = () => authenticated;

  this.setLoggedIn = (username, roles) => {
    authenticated = Promise.resolve(true);
    ga('set', 'userId', username);
    this.username = username;
    this.roles = roles;
  };

  this.getUsername = () => this.username;

  this.setLoggedOut= () => authenticated = Promise.resolve(false);

  this.isTeamAdmin= () => this.roles.indexOf('teamadmin') !== -1;
}
