angular.module('common').service('FileExport', FileExport);


function FileExport(FileSaver, Blob) {
  const UTF8_BOM = '\ufeff';
  const TEXT_UTF8_MIME_TYPE = 'text/csv;charset=utf-8;';

  this.escapeForCsv = (str) => '"' +
  (str+'').replace(/^\s\s*/, '').replace(/\s*\s$/, '') // trim spaces
    .replace(/"/g, '""') + // replace quotes with double quotes
  '"';

  this.exportCsv = (csv) => this.exportFile('export.csv', new Blob([UTF8_BOM + csv], {type: TEXT_UTF8_MIME_TYPE}));

  this.exportFile = (filename, filecontent) => FileSaver.saveAs(filecontent, filename);
}
