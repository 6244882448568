angular.module('fotos').controller('FotosEditController', FotosEditController);

function FotosEditController($scope, FotosFacade, editedFoto, Baustellen, Subunternehmer, Upload) {

  $scope.loading = false;

  $scope.foto = editedFoto || { datum: new Date() };

  $scope.alerts = [];

  $scope.baustellen = [];

  $scope.subunternehmer = [];

  Baustellen.getAll((data) => {
    $scope.baustellen = data;
  });

  Subunternehmer.getAll((data) => {
    $scope.subunternehmer = data;
  });

  $scope.save = (form) => {
    if(form.$valid) {
      $scope.loading = true;
      Upload.base64DataUrl($scope.foto.data)
        .then(data => FotosFacade.save($scope.foto, data))
        .then(() => {
          $scope.loading = false;
          $scope.$close();
        })
        .catch(() => {
          $scope.loading = false;
          $scope.alerts.push({type: 'danger', msg: 'Fehler! Bitte versuchen Sie es später noch einmal.'});
        });
    }
  };
}
