angular.module('common').component('datepicker', {
  bindings: {
    date: '=',
    label: '@',
    disabled: '<',
    limited: '<'
  },
  controller: function (AuthService, TEAM_ADMIN_EDITABLE_DAYS) {
    this.isOpen = false;
    this.open = () => this.isOpen = true;
    this.changed = this.onChange;
    this.dateOptions = {
      startingDay: 1
    };
    if(this.limited && AuthService.isTeamAdmin()) {
      this.dateOptions.minDate = moment().subtract(TEAM_ADMIN_EDITABLE_DAYS, 'days').toDate();
    }
  },
  template: `<div class="input-group">
                <span class="input-group-addon" ng-show="$ctrl.label">{{ $ctrl.label }}</span>
                <input type="text" name="datum" class="form-control" uib-datepicker-popup="dd.MM.yyyy" ng-required="true" ng-disabled="$ctrl.disabled"
                  is-open="$ctrl.isOpen" ng-focus="$ctrl.open()" show-weeks="false" datepicker-options="$ctrl.dateOptions" show-button-bar="false"
                  ng-model="$ctrl.date" ng-change="$ctrl.changed($ctrl.date)"/>
                  <span class="input-group-btn">
                    <button type="button" class="btn btn-default" ng-click="$ctrl.open()"><i class="fa fa-calendar"></i></button>
                  </span>
             </div>`
});


