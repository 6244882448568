angular.module('arbeitszeiten').controller('ArbeitszeitenIndexController', ArbeitszeitenIndexController);

function ArbeitszeitenIndexController($scope, $filter, TableConfiguration, ArbeitszeitenFacade, AuthService, TEAM_ADMIN_EDITABLE_DAYS) {
  $scope.alerts = [];

  $scope.data = [];

  $scope.loading = true;

  $scope.tableParams = TableConfiguration.createTableConfiguration($scope);

  $scope.toDate = new Date();
  $scope.fromDate = new Date();

  let loadData = () => {
    $scope.loading = true;
    ArbeitszeitenFacade.getData(queryParams()).then((data) => {
      $scope.data = $filter('orderBy')(data, ['datum', 'personal.name', compareByVon], false);
      $scope.tableParams.reload();
      $scope.loading = false;
    }).catch(error => {
      $scope.alerts.push({type: 'danger', msg: 'Fehler! Bitte versuchen Sie es später noch einmal.'});
      ga('send', 'event', 'Network error', error, error, 0, true);
    });
  };

  let queryParams = () => {
    let von = moment($scope.fromDate);
    let bis = moment($scope.toDate);
    return {
      startkey: '["az-' + von.format('YYYY-MM-DD') + '"]',
      endkey: '["az-' + bis.format('YYYY-MM-DD') + '\uf0000"]'
    };
  };

  let compareByVon = (az) => moment(az.von, 'HH:mm').unix();

  let onDateChange = () => {
    let von = moment($scope.fromDate);
    let bis = moment($scope.toDate);
    if (von.isSameOrBefore(bis)) {
      loadData();
      $scope.tableParams.page(1);
    }
  };

  $scope.$watchGroup(['toDate', 'fromDate'], onDateChange);

  $scope.new = () => ArbeitszeitenFacade.new().result.then(loadData);

  $scope.delete = (doc) => ArbeitszeitenFacade.delete(doc).then(loadData);

  $scope.edit = (doc) => ArbeitszeitenFacade.edit(doc).result.then(loadData);

  $scope.export = () => ArbeitszeitenFacade.exportCsv(TableConfiguration.filterDataForExport($scope.data, $scope.tableParams));

  $scope.isEditable = (arbeitszeit) => {
    let date = moment(arbeitszeit.datum).startOf('day');
    let threeDaysAgo = moment().subtract(TEAM_ADMIN_EDITABLE_DAYS, 'days').startOf('day');
    return !AuthService.isTeamAdmin() || date.isSameOrAfter(threeDaysAgo);
  };

}
