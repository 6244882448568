angular.module('login')
  .service('LoginService', LoginService);

function LoginService($state, $http, LOGIN_URL, AuthService) {

  function login(username, password) {
    let basicAuthHeader = 'Basic ' + btoa(username + ':' + password);
    return $http.post(LOGIN_URL, {name: username, password: password}, { headers: {Authorization: basicAuthHeader}})
      .then(isAdmin)
      .then((roles) => AuthService.setLoggedIn(username, roles));
  }

  let isAdmin = (res) => {
    let isAdmin = res.data.roles && res.data.roles.indexOf('_admin') !== -1;
    let isTeamadmin = res.data.roles
      && res.data.roles.indexOf('teamadmin') !== -1
      && res.data.roles.indexOf('deactivated') === -1;
    if(isAdmin || isTeamadmin) {
      return res.data.roles;
    }
    throw {notAdmin: true};
  };

  function goToNextView() {
    $state.go('main.arbeitszeiten', {}, {location: 'replace'});
  }

  function logout() {
    return $http.delete(LOGIN_URL)
      .then(AuthService.setLoggedOut)
      .then(() => $state.go('login', {}, {location: 'replace'}))
      .catch(() => $state.go('login', {}, {location: 'replace'}));
  }

  return {
    login: login,
    goToNextView: goToNextView,
    logout: logout
  };
}
