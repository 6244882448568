angular.module('subunternehmer').controller('SubunternehmerEditController', SubunternehmerEditController);

function SubunternehmerEditController($scope, SubunternehmerFacade, editedSubunternehmer) {

  $scope.loading = false;

  $scope.subunternehmer = editedSubunternehmer;

  $scope.alerts = [];

  $scope.save = (form) => {
    if(form.$valid) {
      $scope.loading = true;
      SubunternehmerFacade.update($scope.subunternehmer)
        .then(() => {
          $scope.loading = false;
          $scope.$close();
        })
        .catch(() => {
          $scope.loading = false;
          $scope.alerts.push({type: 'danger', msg: 'Fehler! Bitte versuchen Sie es später noch einmal.'});
        });
    }
  };

  $scope.changePassword = () => {
    $scope.$dismiss();
    return SubunternehmerFacade.changePasswordDialog($scope.subunternehmer);
  };

}
