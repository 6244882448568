'use strict';

angular.module('bautagesbericht')
  .controller('BautagesberichtController', BautagesberichtController);

function BautagesberichtController($scope, $timeout, Baustellen, BautagesberichtService, Angularytics) {
  $scope.loading = false;

  $scope.toDate = new Date();
  $scope.fromDate = new Date();
  $scope.mitFotos = true;

  $scope.baustellen = [];
  $scope.baustelle = '';

  Baustellen.getAll((data) => {
    $scope.baustellen = data.map(b => {
      b.status = b.deactivated ? 'Inaktive Baustellen' : 'Aktive Baustellen';
      return b;
    });
    $scope.baustelle = data[0]._id;
  });

  $scope.downloadReport = () => {
    $scope.loading = true;
    $timeout(() => $scope.loading = false, 10000);
    BautagesberichtService.get($scope.fromDate, $scope.toDate, $scope.baustelle, $scope.mitFotos);
    Angularytics.trackEvent('Reports', 'Bautagesbericht', $scope.baustelle);
  };
}
