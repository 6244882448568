'use strict';

angular.module('berichtconfig')
  .factory('BerichtconfigService', function ($resource, BACKEND_BASE_URL, Upload) {

    let logoResource = $resource(`${BACKEND_BASE_URL}/config/:id/:attachment`, {id: 'logo'});


    function uploadNewLogo(newLogo, rev) {
      return Upload.http({
        url: `${BACKEND_BASE_URL}/config/logo/logo`,
        method: 'PUT',
        headers: {
          'Content-Type': newLogo.type,
          'If-Match': rev
        },
        data: newLogo
      });
    }

    return {
      save: function (newLogo) {
        return logoResource.get().$promise
          .then(oldLogo => uploadNewLogo(newLogo, oldLogo._rev));
      }
    };
  });
