'use strict';

angular.module('stundenauswertung')
  .factory('StundenauswertungBerichtService', function ($window, REPORTS_URL) {
    return {
      get: function (fromDate, toDate, person) {
        fromDate = moment(fromDate).toISOString().slice(0, 10);
        toDate = moment(toDate).toISOString().slice(0, 10);
        $window.location = REPORTS_URL + '/reports/stundenauswertung?von='+fromDate+'&bis='+toDate+'&subunternehmerId='+person;

      }
    };
  });
