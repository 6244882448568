angular.module('common').service('Arbeitszeiten', Arbeitszeiten);

function Arbeitszeiten($resource, BACKEND_URL, AuditService) {

  let methods = {
    getAll : {
      method: 'GET',
      url: BACKEND_URL + '/_design/arbeitszeiten/_list/arbeitszeit/arbeitszeit-with-relations',
      params: {
        include_docs: 'true'
      },
      isArray: true,
      transformResponse: (data) => data ? angular.fromJson(data).map(addDauer).map(personalAddFullName).map(personalAddFilterStrings) : data
    },
    update: {
      method: 'PUT'
    }
  };

  let addDauer = (arbeitszeit) => {
    var bis = moment(arbeitszeit.bis, 'HH:mm');
    var von = moment(arbeitszeit.von, 'HH:mm');

    if(bis.isBefore(von)) {
      bis.add(1, 'day');
    }

    var duration = moment.duration(bis.diff(von));

    arbeitszeit.dauer = (duration.asHours() + '').replace('.', ',');
    return arbeitszeit;
  };

  let personalAddFullName = (arbeitszeit) => {
    arbeitszeit.personal.name = arbeitszeit.personal.nachname + ', ' + arbeitszeit.personal.vorname;
    return arbeitszeit;
  };

  let personalAddFilterStrings = (arbeitszeit) => {
    arbeitszeit.nameFilter = [arbeitszeit.personal.nachname, arbeitszeit.personal.vorname, arbeitszeit.mandant || arbeitszeit.personal.mandant].join('');
    arbeitszeit.baustelleFilter = [arbeitszeit.baustelle.name, arbeitszeit.baustelle.projektnummer, arbeitszeit.baustelle.projektnummer2].join('');
    arbeitszeit.taetigkeitFilter = [arbeitszeit.taetigkeit.name, arbeitszeit.taetigkeit.artikelnummer, arbeitszeit.taetigkeit.artikelname].join('');

    return arbeitszeit;
  };

  let resource = $resource(BACKEND_URL + '/:id', {id: '@id'}, methods);

  this.getAll = resource.getAll;

  this.save = (doc) => {
    doc.type = 'arbeitszeit';
    doc = AuditService.addOrUpdateAuditInfo(doc);
    doc.datum = moment(doc.datum).format('YYYY-MM-DD');
    var user = doc.personal_id.replace('p-','');

    if (doc._id) {
      return resource.update({id: doc._id}, doc).$promise;
    }
    doc._id = getSlug('az:' + doc.datum + ':' + user + ':' + uuid.v1());
    return resource.save(doc).$promise;
  };

  this.delete = (doc) => {
    doc._deleted = true;
    return resource.update({id: doc._id}, doc).$promise;
  };

}
