angular.module('subunternehmer').service('SubunternehmerFacade', SubunternehmerFacade);

function SubunternehmerFacade(Subunternehmer, $uibModal, User) {

  this.getData = () => Subunternehmer.getAll().$promise;

  this.new = () => $uibModal.open({
    templateUrl: 'templates/subunternehmer/new.html',
    controller: 'SubunternehmerNewController'
  });

  this.save = Subunternehmer.create;

  this.update = Subunternehmer.update;

  this.edit = (subunternehmer) => $uibModal.open({
    templateUrl: 'templates/subunternehmer/edit.html',
    controller: 'SubunternehmerEditController',
    resolve: {
      'editedSubunternehmer': () => angular.copy(subunternehmer)
    }
  });

  this.changePasswordDialog = (subunternehmer) => $uibModal.open({
    templateUrl: 'templates/subunternehmer/change.password.html',
    controller: 'SubunternehmerPasswordController',
    resolve: {
      'editedSubunternehmer': () => angular.copy(subunternehmer)
    }
  });

  this.changePassword = (subunternehmerId, password) => User.changePassword(subunternehmerId, password);
}
